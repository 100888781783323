import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import {
  FirebaseStorageService,
  FormOf,
  GuidUtils,
  LanguageService,
  LaunchMissionAction,
  PaginatedResult,
} from '@freddy/common';
import {
  AddTimeAction,
  ArchiveGameAction,
  CancelMissionAction,
  ClearListenToGameChangesAction,
  CloneGameAction,
  DownloadGameAction,
  EvaluateAnswerAction,
  ExcludeTeamAction,
  GameCreatedAction,
  ListenToGameChangesAction,
  ListenToTeamPositionsAction,
  ListGamesAction,
  NextGamePageAction,
  PatchTeamOrGameAction,
  PauseGameAction,
  PrevGamePageAction,
  RemoveTimeAction,
  ResetGameFormAction,
  SaveGameAction,
  SearchGamesAction,
  SearchGamesFailureAction,
  SearchGamesSuccessAction,
  StartGameAction,
  StartMissionsForAllTeamsActions,
  StopGameAction,
} from '../actions/game.action';
import { GameRepository } from '../repository/game.repository';
import { ComboboxItem } from '../../../shared/components/inputs/combobox-input/combobox-input.component';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';
import { Navigate } from '@ngxs/router-plugin';
import { catchError, Observable, Subscription, throwError } from 'rxjs';
import { MissingIdError } from '../../../shared/errors/missing-id.error';
import { AnswerRepository } from '../repository/answer.repository';
import { TeamRepository } from '../repository/team.repository';
import {
  Answer,
  Command,
  Game,
  GameStatus,
  Position,
  Scenario,
  Team,
} from '@freddy/models';
import { CommandRepository } from '../repository/command.repository';
import { add, differenceInSeconds, sub } from 'date-fns';
import { HotToastService } from '@ngneat/hot-toast';
import { tap } from 'rxjs/operators';
import { TenantService } from '../../../core/auth/services/tenant.service';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { TranslateService } from '@ngx-translate/core';
import { SendMessageToUserAction } from '../../chat/actions/chat.actions';
import { Locale } from 'locale-enum';
import { SearchService } from '../../../core/services/search.service';
import { environment } from '../../../../environments/environment';
import { where } from '@angular/fire/firestore';
import { Database, off, onValue, ref } from '@angular/fire/database';
import { DataSnapshot } from '@angular/fire/database/firebase';
export const GAME_STATE_TOKEN = new StateToken<GameStateModel>('game');

export interface GameForm {
  uid?: string;
  name: string;
  scenario: ComboboxItem<Scenario>;
  languagesAvailable: Locale[];
  nbPlayers: number;
  duration: number;
  nbChallenges: number;
  showRanking: boolean;
  emergencyNumber: string;
  companyLogo: File[];
  scheduledGameDate: Date;
  remarks: string;
  code: string;
  challengesEnabled: boolean;
  communicationLanguage: 'fr' | 'nl' | 'en';
  emailForContent: string;
  isDiyMode: boolean;
  isTestMode: boolean;
  enableInterTeamChat: boolean;
  enableGhost: boolean;
  enableHunter: boolean;
  hunterCooldown: number;
  hunterRange: number;
  hunterAttackPoints: number;
  useHighPerformanceMap?: boolean;
}

export interface GameStateModel {
  layout: {
    loading: boolean;
  };
  list: PaginatedResult<Game>;
  gameForm: FormOf<GameForm>;
  gameControl: {
    currentGame?: Game;
    gameSub?: Subscription;
    teamsSub?: Subscription;
    positionsSub?: Subscription;
    teams: Team[];
  };
  searchTerm: string;
  isSearchActive: boolean;
  totalSearchResults: number;
}

const defaultFormValue = {
  gameForm: {
    dirty: false,
    status: '',
    errors: {},
  },
  layout: {
    loading: false,
  },
  list: {
    currentPage: 0,
    items: [],
    prevDisabled: true,
    nextDisabled: false,
  },
  isSearchActive: false,
  totalSearchResults: 0,
  searchTerm: '',
};

@State<GameStateModel>({
  name: GAME_STATE_TOKEN,
  defaults: {
    ...defaultFormValue,
    gameControl: {
      teams: [],
    },
  },
})
@Injectable()
export class GameState {
  private readonly store = inject(Store);
  private readonly gameRepository = inject(GameRepository);
  private readonly tenantService = inject(TenantService);
  private readonly answerRepository = inject(AnswerRepository);
  private readonly teamRepository = inject(TeamRepository);
  private readonly commandRepository = inject(CommandRepository);
  private readonly toastService = inject(HotToastService);
  private readonly firebaseStorage = inject(FirebaseStorageService);
  private readonly translate = inject(TranslateService);
  private readonly languageService = inject(LanguageService);
  private readonly translateService = inject(TranslateService);
  private readonly searchService = inject(SearchService);
  private database = inject(Database);
  private functions = inject(Functions);

  @Selector()
  static isSearchActive(state: GameStateModel): boolean {
    return state.isSearchActive;
  }

  @Selector()
  static searchTerm(state: GameStateModel): string {
    return state.searchTerm;
  }

  @Selector()
  static totalSearchResults(state: GameStateModel): number {
    return state.totalSearchResults;
  }

  @Selector([GameState])
  static listOfGames(state: GameStateModel): PaginatedResult<Game> {
    return state.list;
  }

  @Selector([GameState])
  static controlTeams(state: GameStateModel): Team[] {
    return state.gameControl?.teams ?? [];
  }

  @Selector([GameState])
  static controlGame(state: GameStateModel): Game | undefined {
    return state.gameControl?.currentGame;
  }

  @Selector([GameState])
  static isLoading(state: GameStateModel): boolean {
    return state.layout.loading;
  }

  static getTeam(teamUid: string) {
    return createSelector([GameState], (state: GameStateModel) => {
      return state.gameControl.teams.find((t) => t.uid === teamUid);
    });
  }

  @Action(SaveGameAction)
  async saveGame(ctx: StateContext<GameStateModel>) {
    const gameFormModel = ctx.getState().gameForm.model;
    if (gameFormModel) {
      if (gameFormModel.uid) {
        await this.gameRepository.updateGame({
          uid: gameFormModel.uid,
          gameForm: gameFormModel,
        });
        this.store.dispatch(new Navigate(['/game']));
        this.toastService.success(
          this.translateService.instant('game.gameStore.toast.gameSaved'),
        );
      } else {
        // For new games
        const game = await this.gameRepository.createGame({
          gameForm: gameFormModel,
        });
        this.store.dispatch([
          new GameCreatedAction(game),
          new Navigate(['/game/control', game.uid]), // Redirect to control view
        ]);
        this.toastService.success(
          this.translateService.instant('game.gameStore.toast.gameSaved'),
        );
      }
    }
    ctx.patchState(defaultFormValue);
  }

  @Action(ResetGameFormAction)
  async resetGameForm(ctx: StateContext<GameStateModel>) {
    ctx.patchState(defaultFormValue);
  }

  @Action(GameCreatedAction)
  gameCreatedAction(
    ctx: StateContext<GameStateModel>,
    action: GameCreatedAction,
  ) {
    ctx.setState(
      patch<GameStateModel>({
        list: patch<PaginatedResult<Game>>({
          items: insertItem<Game>(action.game, 0),
        }),
      }),
    );
  }

  @Action(SearchGamesAction)
  searchGames(ctx: StateContext<GameStateModel>, action: SearchGamesAction) {
    ctx.patchState({
      layout: { loading: true },
      isSearchActive: true,
      searchTerm: action.searchTerm,
    });
    return this.searchService
      .search<Game>(environment.typesense.gamesCollectionName, {
        q: action.searchTerm,
        query_by:
          'name,scenario.commonAttributes.scenarioName,scenario.location.name',
        page: action.page,
        per_page: action.pageSize,
      })
      .pipe(
        tap((result) => {
          ctx.dispatch(
            new SearchGamesSuccessAction(
              result.hits?.map((hit) => hit.document) ?? [],
              result.found ?? 0,
            ),
          );
        }),
        catchError((error) => {
          ctx.patchState({ layout: { loading: false } });
          return ctx.dispatch(new SearchGamesFailureAction(error));
        }),
      );
  }

  @Action(SearchGamesSuccessAction)
  searchGamesSuccess(
    ctx: StateContext<GameStateModel>,
    action: SearchGamesSuccessAction,
  ) {
    ctx.patchState({
      list: {
        items: action.games,
        nextDisabled: action.games.length < ctx.getState().list.items.length,
        prevDisabled: ctx.getState().list.currentPage === 1,
        currentPage: ctx.getState().list.currentPage,
      },
      totalSearchResults: action.totalResults,
      layout: { loading: false },
    });
  }

  @Action(SearchGamesFailureAction)
  searchGamesFailure(
    ctx: StateContext<GameStateModel>,
    action: SearchGamesFailureAction,
  ) {
    console.error('Search failed:', action.error);
    this.toastService.error(this.translateService.instant('game.search.error'));
  }

  @Action(NextGamePageAction)
  nextPage(ctx: StateContext<GameStateModel>) {
    const state = ctx.getState();
    if (state.isSearchActive) {
      const nextPage = state.list.currentPage + 1;
      ctx.patchState({ list: { ...state.list, currentPage: nextPage } });
      ctx.dispatch(
        new SearchGamesAction(
          state.searchTerm,
          nextPage,
          state.list.items.length,
        ),
      );
    } else {
      // Use existing pagination for non-search listing
      ctx.dispatch(
        new ListGamesAction({
          startAfter: state.list.lastItem,
          limit: state.list.items.length,
          where: [where('status', '!=', GameStatus.ARCHIVED)],
          orderBy: 'createdAt',
        }),
      );
    }
  }

  @Action(PrevGamePageAction)
  prevPage(ctx: StateContext<GameStateModel>) {
    const state = ctx.getState();
    if (state.isSearchActive && state.list.currentPage > 1) {
      const prevPage = state.list.currentPage - 1;
      ctx.patchState({ list: { ...state.list, currentPage: prevPage } });
      ctx.dispatch(
        new SearchGamesAction(
          state.searchTerm,
          prevPage,
          state.list.items.length,
        ),
      );
    } else if (!state.isSearchActive && state.list.firstItem) {
      // Use existing pagination for non-search listing
      ctx.dispatch(
        new ListGamesAction({
          endBefore: state.list.firstItem,
          limit: state.list.items.length,
          where: [where('status', '!=', GameStatus.ARCHIVED)],
          orderBy: 'createdAt',
        }),
      );
    }
  }

  @Action(ListGamesAction)
  async listGames(ctx: StateContext<GameStateModel>, action: ListGamesAction) {
    const state = ctx.getState();
    ctx.patchState({
      layout: {
        loading: true,
      },
    });

    return this.gameRepository
      .list({
        ...action.query,
        currentPage: state.list.currentPage,
      })
      .pipe(
        tap((paginatedResult) => {
          ctx.patchState({
            layout: {
              loading: false,
            },
            list: {
              ...paginatedResult,
              lastItem:
                paginatedResult.currentPage > 1 &&
                paginatedResult.items.length === 0
                  ? state.list.lastItem
                  : paginatedResult.lastItem,
              firstItem:
                paginatedResult.currentPage > 1 &&
                paginatedResult.items.length === 0
                  ? state.list.firstItem
                  : paginatedResult.firstItem,
            },
          });
        }),
      );
  }

  /**
   * Manages position updates for teams in a game, handling the subscription lifecycle
   * and state updates when positions change.
   */
  @Action(ListenToTeamPositionsAction)
  listenToTeamPositionsAction(
    ctx: StateContext<GameStateModel>,
    action: ListenToTeamPositionsAction,
  ): void {
    // First clean up any existing subscription
    this.cleanupPositionSubscription(ctx);

    // Create and store the new subscription
    const subscription = this.createPositionsSubscription(ctx, action.gameUid);
    this.storePositionSubscription(ctx, subscription);
  }

  @Action(PatchTeamOrGameAction)
  patchTeamOrGameAction(
    ctx: StateContext<GameStateModel>,
    action: PatchTeamOrGameAction,
  ) {
    if (action.teams)
      ctx.setState(
        patch<GameStateModel>({
          gameControl: patch({
            teams: action.teams,
          }),
        }),
      );
    if (action.game)
      ctx.setState(
        patch<GameStateModel>({
          gameControl: patch({
            currentGame: action.game,
          }),
        }),
      );
  }

  @Action(ListenToGameChangesAction)
  async listenToGameChangesAction(
    ctx: StateContext<GameStateModel>,
    action: ListenToGameChangesAction,
  ) {
    this.store.dispatch(new ListenToTeamPositionsAction(action.gameUid));
    this.clearListenToGameChangesAction(ctx);
    const teamsSub = this.gameRepository
      .getTeamsChange(action.gameUid)
      .subscribe((teams) => {
        // Exclude position data from team updates
        const teamsWithoutPosition = teams.map(
          ({ currentPosition, ...team }) => team,
        );
        ctx.dispatch(
          new PatchTeamOrGameAction(undefined, teamsWithoutPosition),
        );

        console.log(ctx.getState());
      });

    const gameSub = this.gameRepository
      .getDocChanges(action.gameUid)
      .subscribe((game) => {
        ctx.dispatch(new PatchTeamOrGameAction(game, undefined));
      });

    ctx.setState(
      patch<GameStateModel>({
        gameControl: patch({
          teamsSub: teamsSub,
          gameSub: gameSub,
        }),
      }),
    );
  }

  @Action(ClearListenToGameChangesAction)
  clearListenToGameChangesAction(ctx: StateContext<GameStateModel>) {
    if (ctx.getState().gameControl.teamsSub) {
      ctx.getState().gameControl.teamsSub?.unsubscribe();
    }
    if (ctx.getState().gameControl.positionsSub) {
      ctx.getState().gameControl.positionsSub?.unsubscribe();
    }
  }

  @Action(StartGameAction)
  startGame(ctx: StateContext<GameStateModel>) {
    const gameUid = ctx.getState().gameControl.currentGame?.uid;
    const duration = ctx.getState().gameControl.currentGame?.duration;
    const game = ctx.getState().gameControl.currentGame;
    if (game?.status === GameStatus.ONGOING) {
      return;
    }
    if (!gameUid) {
      throw new MissingIdError('uid is mandatory');
    }

    let plannedEndDate;

    if (game?.pausedAt && game?.plannedEndDate) {
      // Calculate the difference in minutes between now and when the game was paused
      const pausedDuration = differenceInSeconds(
        new Date(),
        new Date(game.pausedAt),
      );
      // Calculate the new planned end date by adding the paused duration to the original duration
      plannedEndDate = add(game.plannedEndDate, { seconds: pausedDuration });
    } else {
      plannedEndDate = add(new Date(), { minutes: duration ?? 0 });
    }

    return this.gameRepository.update({
      uid: gameUid,
      status: GameStatus.ONGOING,
      plannedEndDate: plannedEndDate,
      pausedAt: null,
    });
  }

  @Action(StopGameAction)
  stopGame(ctx: StateContext<GameStateModel>) {
    const gameUid = ctx.getState().gameControl.currentGame?.uid;
    if (!gameUid) {
      throw new MissingIdError('uid is mandatory');
    }
    return this.gameRepository.update({
      uid: gameUid,
      pausedAt: new Date(),
      status: GameStatus.STOPPED,
    });
  }

  @Action(PauseGameAction)
  pauseGame(ctx: StateContext<GameStateModel>) {
    const gameUid = ctx.getState().gameControl.currentGame?.uid;
    if (!gameUid) {
      throw new MissingIdError('uid is mandatory');
    }
    return this.gameRepository.update({
      uid: gameUid,
      pausedAt: new Date(),
      status: GameStatus.PAUSE,
    });
  }

  @Action(AddTimeAction)
  addTimeAction(ctx: StateContext<GameStateModel>) {
    const currentPlannedEndDate =
      ctx.getState().gameControl.currentGame?.plannedEndDate;
    if (!currentPlannedEndDate) {
      return;
    }

    const plannedEndDate = add(currentPlannedEndDate, { minutes: 5 });
    const gameUid = ctx.getState().gameControl.currentGame?.uid;
    if (!gameUid) {
      throw new MissingIdError('uid is mandatory');
    }
    this.toastService.success(
      this.translate.instant('game.gameStore.toast.addTime'),
    );
    return this.gameRepository.update({
      uid: gameUid,
      plannedEndDate: plannedEndDate,
    });
  }

  @Action(RemoveTimeAction)
  removeTimeAction(ctx: StateContext<GameStateModel>) {
    const currentPlannedEndDate =
      ctx.getState().gameControl.currentGame?.plannedEndDate;
    if (!currentPlannedEndDate) {
      return;
    }
    const plannedEndDate = sub(currentPlannedEndDate, { minutes: 5 });

    const gameUid = ctx.getState().gameControl.currentGame?.uid;
    if (!gameUid) {
      throw new MissingIdError('uid is mandatory');
    }
    this.toastService.success(
      this.translate.instant('game.gameStore.toast.removeTime'),
    );
    return this.gameRepository.update({
      uid: gameUid,
      plannedEndDate: plannedEndDate,
    });
  }

  @Action(ExcludeTeamAction)
  excludeTeam(ctx: StateContext<GameStateModel>, action: ExcludeTeamAction) {
    const state = ctx.getState();
    const currentGame = state.gameControl.currentGame;
    if (currentGame) {
      return this.gameRepository.excludeTeam(currentGame, action.team);
    }
    return;
  }

  @Action(EvaluateAnswerAction)
  evaluateAnswerAction(
    ctx: StateContext<GameStateModel>,
    action: EvaluateAnswerAction,
  ) {
    const newAnswer: Answer = {
      ...action.answer,
      isEvaluated: true,
      points: action.points,
    };
    const teamLanguage = ctx
      .getState()
      .gameControl.teams.find((team) => team.uid === action.team.uid)?.language;

    this.store.dispatch(
      new SendMessageToUserAction(
        'TECH_MESSAGE.missionEvaluated',
        action.team.uid,
        {
          points: action.points + '',
          mission: this.languageService.getTitle(
            action.mission,
            teamLanguage ?? Locale.en,
          ),
        },
      ),
    );
    return Promise.all([
      this.answerRepository.update(newAnswer, { gameUid: action.game.uid }),
    ]);
  }

  @Action(CancelMissionAction)
  async cancelMissionAction(
    ctx: StateContext<GameStateModel>,
    action: CancelMissionAction,
  ) {
    const state = ctx.getState();
    const gameUid = state.gameControl.currentGame?.uid;
    if (gameUid) {
      for (const team of state.gameControl.teams) {
        team.missionAccomplished.push(action.missionUid);
        this.teamRepository.update(team, {
          gameUid: gameUid,
        });
      }
    }
  }

  @Action(StartMissionsForAllTeamsActions)
  async launchMissionAction(
    ctx: StateContext<GameStateModel>,
    action: StartMissionsForAllTeamsActions,
  ) {
    const teams = ctx.getState().gameControl.teams;
    const game = ctx.getState().gameControl.currentGame;
    const actionToDispatch = new LaunchMissionAction(action.missionUid);
    if (game) {
      for (const team of teams) {
        const command: Command = {
          uid: GuidUtils.generateUuid(),
          recipientTeamUid: team.uid,
          action: actionToDispatch,
          executed: false,
        };
        this.commandRepository.sendCommand(command, {
          gameUid: game.uid,
        });
      }
    }
  }

  @Action(DownloadGameAction, { cancelUncompleted: true })
  async downloadGameAction(
    ctx: StateContext<GameStateModel>,
    action: DownloadGameAction,
  ) {
    const toast = this.toastService.loading(
      this.translate.instant('game.gameStore.toast.downloadPreparing'),
    );
    const organizationId = this.tenantService.currentOrganizationSlug;
    if (!organizationId) {
      throw new Error('Organization id is mandatory');
    }
    const callable = httpsCallable<
      { organizationId: string; gameId: string },
      { url: string }
    >(this.functions, 'downloadgamecontent');
    return fromPromise(
      callable({
        organizationId: organizationId,
        gameId: action.game.uid,
      }),
    ).pipe(
      tap((res) => {
        toast.close();
        this.toastService.success(
          this.translate.instant('game.gameStore.toast.downloadReady'),
        );
        this.firebaseStorage.startDownload(
          `game-${action.game.code}.zip`,
          res.data.url,
        );
      }),
      catchError((err) => {
        toast.close();
        this.toastService.error(
          this.translate.instant('game.gameStore.toast.downloadError'),
        );
        return throwError(() => err);
      }),
    );
  }

  @Action(CloneGameAction)
  async cloneGame(ctx: StateContext<GameStateModel>, action: CloneGameAction) {
    const clonedGame = await this.gameRepository.cloneGame(action.game);
    ctx.dispatch(new GameCreatedAction(clonedGame));
  }

  @Action(ArchiveGameAction)
  archiveGame(ctx: StateContext<GameStateModel>, action: ArchiveGameAction) {
    const allowedStatuses = [
      GameStatus.PLANNED,
      GameStatus.STOPPED,
      GameStatus.DONE,
    ];

    if (!allowedStatuses.includes(action.game.status)) {
      this.toastService.error(
        this.translateService.instant('game.gameStore.toast.cannotArchiveGame'),
      );
      return throwError(
        () => new Error('Cannot archive game in current status'),
      );
    }

    return this.gameRepository.archiveGame(action.game).pipe(
      tap(() => {
        // Remove the archived game from the list
        const currentList = ctx.getState().list;
        const updatedItems = currentList.items.filter(
          (game) => game.uid !== action.game.uid,
        );

        ctx.patchState({
          list: {
            ...currentList,
            items: updatedItems,
          },
        });

        // Show a success message
        this.toastService.success(
          this.translateService.instant('game.gameStore.toast.gameArchived'),
        );
      }),
      catchError((error) => {
        console.error('Error archiving game:', error);
        this.toastService.error(
          this.translateService.instant(
            'game.gameStore.toast.gameArchivedError',
          ),
        );
        return throwError(() => new Error('Failed to archive game'));
      }),
    );
  }

  /**
   * Safely cleans up any existing position subscription to prevent memory leaks.
   * We get the subscription state once and reuse it to avoid multiple state reads.
   */
  private cleanupPositionSubscription(ctx: StateContext<GameStateModel>): void {
    const existingSub = ctx.getState().gameControl.positionsSub;
    if (existingSub) {
      existingSub.unsubscribe();
    }
  }

  /**
   * Creates a new subscription to listen for team position updates.
   * Uses Firebase's Realtime Database to watch for position changes.
   */
  private createPositionsSubscription(
    ctx: StateContext<GameStateModel>,
    gameUid: string,
  ): Subscription {
    const positionsRef = ref(this.database, `games/${gameUid}/positions`);

    return new Observable<void>((observer) => {
      // Set up the Firebase listener
      const unsubscribe = onValue(
        positionsRef,
        (snapshot) => this.handlePositionUpdate(ctx, gameUid, snapshot),
        (error) => this.handlePositionError(gameUid, error),
      );

      // Return cleanup function that Firebase will call on unsubscribe
      return () => {
        unsubscribe(); // Remove the Firebase listener
        off(positionsRef); // Clean up any remaining Firebase references
        this.logUnsubscribe(gameUid);
      };
    }).subscribe();
  }

  /**
   * Updates the state for each team's position when new position data arrives.
   * Only processes the update if valid position data is present.
   */
  private handlePositionUpdate(
    ctx: StateContext<GameStateModel>,
    gameUid: string,
    snapshot: DataSnapshot,
  ): void {
    const positions = snapshot.val() as Position | null;
    if (!positions) return;

    // Process each team's position update
    Object.entries(positions).forEach(([userId, position]) => {
      this.updateTeamPosition(ctx, userId, position);
    });
  }

  /**
   * Updates a single team's position in the state.
   * Uses NGXS patch operator for immutable state updates.
   */
  private updateTeamPosition(
    ctx: StateContext<GameStateModel>,
    userId: string,
    position: Position,
  ): void {
    ctx.setState(
      patch<GameStateModel>({
        gameControl: patch({
          teams: updateItem<Team>(
            (team) => team.userUid === userId,
            patch({
              currentPosition: position,
            }),
          ),
        }),
      }),
    );
  }

  /**
   * Handles any errors that occur during position updates.
   * Currently logs errors but could be extended to handle specific error types.
   */
  private handlePositionError(gameUid: string, error: Error): void {
    console.error(`Error fetching positions for game ${gameUid}:`, error);
  }

  /**
   * Stores the subscription in the state so it can be cleaned up later.
   */
  private storePositionSubscription(
    ctx: StateContext<GameStateModel>,
    subscription: Subscription,
  ): void {
    ctx.setState(
      patch<GameStateModel>({
        gameControl: patch({
          positionsSub: subscription,
        }),
      }),
    );
  }

  /**
   * Logs when a subscription is cleaned up.
   */
  private logUnsubscribe(gameUid: string): void {
    console.info(`Unsubscribed from team position updates for game ${gameUid}`);
  }
}
